import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "assets/images/logos/Pin.png";
import markerIcon2x from "assets/images/logos/Pin.png";

// Precisamos corrigir o ícone padrão do Leaflet para funcionar corretamente com React
delete L.Icon.Default.prototype._getIconUrl;

// Configurar o ícone padrão
const customMarkerIcon = new L.Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  iconSize: [33, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function Mapa() {
  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <MapContainer
        center={[-3.7056222, -40.34556]}
        zoom={15}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[-3.7056222, -40.34556]} icon={customMarkerIcon}>
          <Popup>Icetel</Popup>
        </Marker>
        <Marker position={[-3.91377, -40.38294]} icon={customMarkerIcon}>
          <Popup>Loja Groaíras</Popup>
        </Marker>
        <Marker position={[-4.3602329, -39.3134683]} icon={customMarkerIcon}>
          <Popup>Loja Canindé</Popup>
        </Marker>
        <Marker position={[-3.689478, -40.3484855, 21]} icon={customMarkerIcon}>
          <Popup>Loja Sobral</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default Mapa;
