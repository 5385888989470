/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function RegrasPromoSJ() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto", mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" - TV Smart" title="1º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" - Smartphone" title="2° prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" - Air fryer" title="3º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={6} suffix=" meses de Internet" title="4º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" - Alexa" title="5º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" - Fone de ouvido" title="6º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={6} suffix=" meses de Internet móvel" title="7º prêmio" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultCounterCard count={1} suffix=" ano de Paramount +" title="8º prêmio" />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default RegrasPromoSJ;
