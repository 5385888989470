/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgFront1 from "assets/Cards Gustavo/200 + 8.png";
import bgFront2 from "assets/Cards Gustavo/300 + 12 .png";
import bgFront3 from "assets/Cards Gustavo/500 + 30.png";
import bgBack from "assets/Cards Gustavo/200_1.png";
import bgBack2 from "assets/Cards Gustavo/300_1.png";
import bgBack3 from "assets/Cards Gustavo/500_1.png";

function PlanosMoveis() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container spacing={0} alignItems="center" justifyContent="center" sx={{ mx: "auto" }}>
          {" "}
          <Grid item xs={10} lg={3} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront1}
                icon="touch_app"
                title={<>200 MEGA</>}
                description="All the MUI components that you need in a development have been re-design with the new look."
              />
              <RotatingCardBack
                image={bgBack}
                description="Combo veloz: internet rápida para sua rotina digital."
                videoLink="https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20200%20Megas%20Móveis"
                action={{
                  type: "internal",
                  route:
                    "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20200%20Megas%20Móveis",
                  label: "CONTRATE JÁ",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={10} lg={3} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront2}
                icon="touch_app"
                title={<>300 MEGA</>}
                description="All the MUI components that you need in a development have been re-design with the new look."
              />
              <RotatingCardBack
                image={bgBack2}
                title="Discover More"
                description="Oferta ampla: navegação constante em alta velocidade."
                videoLink="https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20300%20Megas%20Móveis"
                action={{
                  type: "internal",
                  route:
                    "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20300%20Megas%20Móveis",
                  label: "CONTRATE JÁ",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={10} lg={3} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront3}
                icon="touch_app"
                title={<>500 MEGA</>}
                description="All the MUI components that you need in a development have been re-design with the new look."
              />
              <RotatingCardBack
                image={bgBack3}
                title="Discover More"
                description="Pacote ágil: conexão eficiente para todas as necessidades."
                videoLink="https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20500%20Megas%20Móveis"
                action={{
                  type: "internal",
                  route:
                    "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20500%20Megas%20Móveis",
                  label: "CONTRATE JÁ",
                }}
              />
            </RotatingCard>
          </Grid>{" "}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PlanosMoveis;
