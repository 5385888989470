/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
// import teste from "../../../assets2/PDF/Regulamento.pdf";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
// import data from "pages/Presentation/sections/data/designBlocksData";

function TitlePromoSJ() {
  const rules = [
    "Promoção válida do dia 01/08/2024 a 19/12/2024;",
    "O Sorteio será realizado no dia 20/12/2024, será transmitido nas redes oficiais da Sobralnet e os participantes também poderão acompanhar presencialmente;",
    "Os prêmios serão sorteados para todos os clientes que contratarem dentro do período da campanha;",
    "Cada sorteado só poderá concorrer a um prêmio;",
    "Para concorrer aos prêmios, os clientes precisam estar ativos na base e adimplentes;",
    "Aos clientes ativos, além de receberem um PIX por cada indicação, poderão concorrer ao sorteio;",
    "Participam automaticamente do sorteio todos os clientes sem atrasos nos pagamentos no período da campanha;",
  ];

  const half = Math.ceil(rules.length / 2);
  const firstHalf = rules.slice(0, half);
  const secondHalf = rules.slice(half);

  return (
    <MKBox component="section" my={0} py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="REGRAS PROMOÇÃO"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Prêmios Sobralnet
          </MKTypography>
          <MKTypography variant="body1" color="text" sx={{ textAlign: "left", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ul>
                  {firstHalf.map((rule, index) => (
                    <li key={index}>{rule}</li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <ul>
                  {secondHalf.map((rule, index) => (
                    <li key={index}>{rule}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </MKTypography>
          {/*
          <Button color="primary" size="large" target="_blank" href={teste} download sx={{ mt: 4 }}>
            Baixar Regulamento (PDF)
          </Button>
          */}
        </Grid>
      </Container>
      {/* <Container sx={{ mt: 6 }}>{renderData}</Container> */}
    </MKBox>
  );
}

export default TitlePromoSJ;
