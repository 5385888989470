/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components

// @mui icons
import { Wifi, Help, Instagram } from "@mui/icons-material";

// Pages
/* import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography"; */

const routes = [
  {
    name: "Planos",
    icon: <Wifi />,
    collapse: [
      {
        name: "200 Megas",
        description: "Navegação ágil e suave na internet",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20200%20Megas",
      },
      {
        name: "300 Megas",
        description: "Velocidade impressionante para tarefas online sem atrasos",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20300%20Megas",
      },
      /*       {
        name: "400 Megas",
        description: "Transferência rápida de dados sem interrupções",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20400%20Megas",
      }, */
      {
        name: "500 Megas",
        description: "Conexão rápida e estável para streaming e downloads",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20500%20Megas",
      },
      /*       {
        name: "600 Megas + Paramount",
        description: "Conexão ultrarrápida e acesso exclusivo à Paramount",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Quero%20ser%20cliente%20600%20Megas",
      }, */
    ],
  },
  {
    name: "Suporte",
    icon: <Help />,
    collapse: [
      {
        name: "Portal do Cliente",
        description: "Plataforma central para pagamentos seguros e detalhes contratuais",
        href: "https://portal.sobralnet.com.br/auth/login",
      },
      {
        name: "Atendimento Via WhatsApp",
        description: "Canal ágil para suporte comercial via WhatsApp",
        href: "https://api.whatsapp.com/send?phone=558009111000&text=Comercial",
      },
      {
        name: "App do Cliente",
        description: "Praticidade e controle móvel para pagamentos",
        dropdown: true,
        collapse: [
          {
            name: "Android",
            href: "https://play.google.com/store/apps/details?id=com.portal.sobralnet&pli=1",
          },
          {
            name: "Iphone",
            href: "https://apps.apple.com/br/app/sobralnet/id1563266011",
          },
        ],
      },
    ],
  },
  {
    name: "Instagram",
    icon: <Instagram />,
    href: "https://www.instagram.com/sobralnet/",
  },
];

export default routes;
