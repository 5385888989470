import PropTypes from "prop-types";
import MKBox from "components/MKBox";

function RotatingCard({ image, videoLink }) {
  const handleClick = () => {
    window.location.href = videoLink; // Redirecionando para o link do vídeo ao clicar
  };

  return (
    <MKBox
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundImage: () => `url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
      }}
      onClick={handleClick} // Adicionando o evento de clique
    ></MKBox>
  );
}

RotatingCard.defaultProps = {
  color: "info",
};

RotatingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  videoLink: PropTypes.string.isRequired, // Adicionando prop para o link do vídeo
};

export default RotatingCard;
