/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Avaliações dos Clientes</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            1,000+ Avaliações
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Temos recebido relatos positivos de clientes que utilizam os nossos serviços e expressam
            sua satisfação.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Francisco Lucidio"
              date="há 4 meses"
              review="Sou muito suspeito para comentar, mais esse produto é maravilhoso cobertura completa e toda região da zona note, totalmente acessível e viável, parabéns #SOBRALNET."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Lucidalva Santana"
              date="há 4 meses"
              review="Então não tenho que reclamar sobre sobralNet, eu tou bem satisfeita com a internet. Super recomendo inclusive eu procurei bastante antes de chegar em vocês, e foi aí que conheci sobralNet."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Sophi Vasconcelos"
              date="há 2 meses"
              review="Sempre com as melhores novidades ♥."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
