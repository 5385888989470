export default function BarCode() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      viewBox="0 0 511.626 511.627"
      style={{ enableBackground: "new 0 0 511.626 511.627" }}
      xmlSpace="preserve"
    >
      <g>
        <g>
          <rect x="134.761" y="54.816" width="17.699" height="401.707" />
          <rect x="98.786" y="54.816" width="8.848" height="401.707" />
          <rect x="197.568" y="54.816" width="8.852" height="401.707" />
          <rect x="179.581" y="54.816" width="8.852" height="401.707" />
          <rect x="26.84" y="54.816" width="9.136" height="401.707" />
          <rect x="53.959" y="54.816" width="8.851" height="401.707" />
          <rect y="54.816" width="17.987" height="401.994" />
          <rect x="215.557" y="54.816" width="8.852" height="401.707" />
          <rect x="394.856" y="54.816" width="17.986" height="401.707" />
          <rect x="439.966" y="54.816" width="26.837" height="401.707" />
          <rect x="475.653" y="54.816" width="9.134" height="401.707" />
          <rect x="493.64" y="54.816" width="17.986" height="401.994" />
          <rect x="332.045" y="54.816" width="17.987" height="401.707" />
          <rect x="368.019" y="54.816" width="17.987" height="401.707" />
          <rect x="296.072" y="54.816" width="17.986" height="401.707" />
          <rect x="251.243" y="54.816" width="17.989" height="401.707" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
