// @mui icons

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images

export default {
  brand: "",
  socials: [],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      By Gustavo Abreu
      <MKTypography
        component="a"
        href="https://github.com/gustavoabreu02"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      ></MKTypography>
      .
    </MKTypography>
  ),
};
