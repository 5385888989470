/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function RegrasPromo() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto", mt: 3 }}>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              count={3}
              suffix=" meses"
              title="3 meses de contrato"
              description="Válida para clientes adimplentes com no mínimo 3 meses de contrato."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              count={24}
              suffix=" horas"
              title="Pix em 24 horas"
              description="O PIX será realizado 24 horas após a ativação do novo titular."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              prefix="R$ "
              count={600}
              suffix=",00"
              title="Até R$600,00"
              description="Valor acumulativo por indicação pode chegar a R$600,00."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              count={50}
              suffix="%"
              title="50% do valor do plano"
              description="O valor pago será 50% do plano contratado pelo cliente indicado, liberado em espécie ou PIX."
            />
          </Grid>
          {/* Adicione novos cartões conforme necessário */}
        </Grid>
        <Grid item xs={12} md={6}>
          <DefaultCounterCard
            count={12}
            suffix=" meses"
            title="Contrato fidelidade"
            description="Contrato fidelidade de 12 meses na modalidade pré-paga."
          />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default RegrasPromo;
