/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKButton from "components/MKButton";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgPortal from "assets/images/desktop_1.png";

function BuiltByDevelopers() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.5),
            rgba(gradients.dark.state, 0.5)
          )}, url(${bgPortal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            Autoatendimento
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            Portal do Cliente ㅤㅤㅤ
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            O Portal do Cliente Voalle oferece acesso completo a dados contratuais, faturas e opções
            de pagamento variadas, proporcionando uma navegação fácil para gerenciar contratos e
            acessar informações essenciais de forma conveniente.
          </MKTypography>
          <MKButton
            component="a"
            href="https://portal.sobralnet.com.br/auth/login"
            target="_blank"
            rel="noreferrer"
            variant="contained"
            color="primary" // Altere para a cor desejada para se destacar
            size="large" // Ajuste o tamanho conforme necessário
            sx={{
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)", // Cor de fundo ao passar o mouse
              },
            }}
          >
            Acesse o Portal <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
